<template>
  <div id="api-product-middle-page">
    <header>
      <div class="product-name-container flex-center">
        <img :src="logoUrl" alt="" />
        <span>{{ productName }}</span>
      </div>
      <h1>恭喜您！机构已受理</h1>
      <p>30分钟内会有工作人员与您复核相关信息，请注意接听</p>
      <div class="loan-card">
        <div>{{ topDisplayLoanMoney }}</div>
      </div>
    </header>
    <div class="product-info-container">
      <div
        class="product-info-item"
        v-for="(productInfo, index) in productInfoList"
        :key="productInfo.name"
        v-show="index < 2 || !productInfoContainerIsShrink"
      >
        <div class="product-info-item-name">{{ productInfo.name }}</div>
        <div>{{ productInfo.content }}</div>
      </div>
    </div>
    <div
      class="product-info-container-operate-button flex-center"
      @click="productInfoContainerIsShrink = !productInfoContainerIsShrink"
      v-show="productInfoList.length === 5"
    >
      <img
        src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/api-product-middle-page/arrow-down.png"
        alt=""
        v-show="productInfoContainerIsShrink"
      />
      <span v-show="!productInfoContainerIsShrink">收起</span>
      <img
        src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/api-product-middle-page/arrow-up.png"
        alt=""
        v-show="!productInfoContainerIsShrink"
      />
    </div>
    <div class="bottom-container" @click="doJump" v-show="showBottomButton">
      <img
        src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/api-product-middle-page/submit-button.png"
        alt=""
      />
    </div>
    <MyLoading :show="loadingIsShow" />
    <van-popup
      v-model="protocolPopupIsShow"
      position="bottom"
      :close-on-click-overlay="false"
      round
    >
      <div class="protocol-popup-container">
        <div
          class="protocol-content-container"
          ref="protocol-content-container"
        >
          <van-tabs v-model="protocolsTitleIndex" animated :ellipsis="false">
            <van-tab
              v-for="(item, index) in bigAgreementName"
              :title="item"
              :key="index"
            >
              <div
                v-if="item === '个人信息授权书' && protocolPopupIsShow"
                v-html="protocolContentHtml"
                :class="{
                  'protocol-content': true,
                  'protocol-content-no-scroll': stopSubmit,
                }"
                ref="content"
              ></div>
              <iframe
                v-else
                :src="
                  bigAgreementName.length === 2
                    ? bigAgreementFile[0]
                    : bigAgreementFile[index]
                "
                frameborder="0"
              />
            </van-tab>
          </van-tabs>
        </div>
        <div
          class="protocol-do-agree-button"
          v-throttle="2000"
          @click="doSubmitApiProduct"
        >
          我同意并申请
        </div>
        <div class="protocol-refuse-button" @click="refuseApiProductProtocol">
          我不同意
        </div>
      </div>
    </van-popup>
    <van-popup
      round
      v-model="refuseProtocolPopupIsShow"
      :close-on-click-overlay="false"
    >
      <div class="refuse-protocol-popup-container">
        <h3>流程太繁琐？</h3>
        <p>我们采用自动化AI匹配</p>
        <p>您所填写的资料我们会高度保密</p>
        <img
          src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/loan-info/finish-apply-button.png"
          alt=""
          v-throttle="2000"
          @click="doSubmitApiProduct"
        />
        <img
          src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/loan-info/to-super-loan-button.png"
          alt=""
          @click="apiProductApplyCancel"
        />
      </div>
    </van-popup>
    <div class="count-down-cover" v-show="countDownCoverIsShow">
      <img
          :src="$store.state.isDarkMode ? 'https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/loan-info/count-down-cover-image-dark.gif' : 'https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/loan-info/count-down-cover-image.gif'"
          alt=""
      >
    </div>
  </div>
</template>

<script>
import { get_agreement_list } from "_network/submitInfo";
import {
  match_product_by_post_user_message,
  apply_big_loan_product,
  save_personal_protocol_image,
} from "_network/product";
import { get_download_url, get_ios_download_url } from "_network/download";

import personalContent from "_utils/personalContent";

import MyLoading from "_components/MyLoading";
import getNowDate from "_utils/time";
import html2canvas from "html2canvas";
import { apply_api_product } from "_network/apiProduct";
import { api_product_user_apply } from "_network/statisticsUv";
import getSearch from "_utils/getSearch";

export default {
  components: {
    MyLoading,
  },
  data() {
    return {
      downloadUrl: "",
      //手机型号
      isIos: "",
      isAndroid: "",
      timer: null,
      // 顶部展示价格
      topDisplayLoanMoney: Math.ceil(Math.random() * 25) * 1000 + 35000,
      apiProduct: {},
      // 产品信息列表
      productInfoList: [],
      // 产品名称
      productName: "",
      // logo
      logoUrl: "",
      // 产品信息列表是否为收缩状态
      productInfoContainerIsShrink: true,
      // 底部按钮是否展示
      showBottomButton:
        !process.env.VUE_APP_PRODUCT_DISPLAY_PAGE_NOT_DO_REDIRECT,
      // 以下是特殊渠道再次匹配产品
      loadingIsShow: false,
      product: {},
      protocolPopupIsShow: false,
      // 多个个人信息授权书弹框列表时默认展示的协议索引值
      protocolsTitleIndex: 0,
      // 协议名称展示集合
      bigAgreementName: ["个人信息授权书"],
      // 协议展示地址集合
      bigAgreementFile: [],
      // 个人信息授权书内容
      protocolContentHtml: "",
      // 该状态在协议弹框弹出时为true，在协议截图完成时改为false，用来阻止用户滑动协议以及点击提交申请的按钮
      stopSubmit: false,
      // 产品匹配成功后3秒的遮罩层显隐
      countDownCoverIsShow: false,
      // 用户点击协议弹框的 我不同意 后弹出的确认弹框
      refuseProtocolPopupIsShow: false,
      //除去API产品，其余跳下载页
      successToDown: process.env.VUE_APP_API_SUCCESS_TO_DOWN,
    };
  },
  methods: {
    // 获取展示列表文案
    getProductInfoList() {
      const {
        name,
        companyName,
        address,
        apiName,
        apiCompanyName,
        apiCompanyAddress,
        logo,
        apiLogo,
      } = this.apiProduct;

      if (apiName && apiCompanyName && apiCompanyAddress) {
        // 这里是三方信息返回全的情况下
        this.productInfoList = [
          {
            name: "助贷机构",
            content: apiCompanyName,
          },
          {
            name: "公司地址",
            content: apiCompanyAddress,
          },
          {
            name: "服务产品",
            content: name,
          },
          {
            name: "服务机构",
            content: companyName,
          },
          {
            name: "服务公司地址",
            content: address,
          },
        ];
        this.productName = apiName;
        this.logoUrl = apiLogo || logo;

        // 产品信息列表需要缩略部分信息，但是可以点击展开
      } else {
        // 三方返回信息不全则展示本地后台配置信息
        this.productInfoList = [
          {
            name: "助贷机构",
            content: companyName,
          },
          {
            name: "公司地址",
            content: address,
          },
        ];
        this.productName = name;
        this.logoUrl = logo;
      }
    },
    // 跳转
    doJump() {
      // 先清除定时器
      clearTimeout(this.timer);

      if (!this.showBottomButton) {
        // 如果底部按钮不进行展示的情况下不做任何跳转
        return;
      }

      if (process.env.VUE_APP_NOT_TO_SUPER_LOAN_REDIRECT_DOWNLOAD) {
        // 特殊渠道的配置直接跳下载
        this.$toDownload(0);
        return;
      }

      if (
        process.env.VUE_APP_API_PRODUCT_MIDDLE_PAGE_MATCH_PRODUCT &&
        localStorage.getItem("apiProductMiddleNotMatchAgain") !== "1"
      ) {
        // 这里是特殊渠道，需要再次匹配产品
        this.matchProduct();
        return;
      }

      this.$router.replace("/superLoan");
    },
    //获取个人信息授权书协议
    async getProtocolContentHtml() {
      this.bigAgreementName = ["个人信息授权书"];
      this.bigAgreementFile = [];
      try {
        const { data } = await get_agreement_list();
        data.forEach((protocol) => {
          if (protocol.name === "个人信息授权书") {
            this.protocolContentHtml = protocol.content;
            if (process.env.VUE_APP_IS_SPECIAL_PERSONAL) {
              this.protocolContentHtml = personalContent;
            }
            if (process.env.VUE_APP_PROTOCOL_PLATFORM_NAME) {
              this.protocolContentHtml = this.protocolContentHtml.replaceAll(
                "{productName}",
                process.env.VUE_APP_PROTOCOL_PLATFORM_NAME
              );
            }
            if (process.env.VUE_APP_PROTOCOL_COMPANY_NAME) {
              this.protocolContentHtml = this.protocolContentHtml.replaceAll(
                "{productCompanyName}",
                process.env.VUE_APP_PROTOCOL_COMPANY_NAME
              );
            }
            localStorage.setItem("agreementId", protocol.id);
          }
        });
      } catch (e) {}
    },
    // 匹配产品
    async matchProduct() {
      // 处理匹配时的资料参数
      const userInfo = this.$getUserInfo();

      switch (userInfo.loanMoney) {
        case "20万以上":
          userInfo.loanMoney = 300000;
          break;
        case "10-20万":
          userInfo.loanMoney = 200000;
          break;
        case "5-10万":
          userInfo.loanMoney = 100000;
          break;
        case "1-5万":
          userInfo.loanMoney = 50000;
          break;
        case "2000元-1万":
          userInfo.loanMoney = 10000;
          break;
      }
      userInfo.overdue =
        userInfo.overdue === "当前无逾期" ? "无逾期" : userInfo.overdue;

      // 正式提交资料匹配产品
      try {
        this.loadingIsShow = true;
        const { data } = await match_product_by_post_user_message(userInfo);

        // 没有产品直接跳贷超
        if (!data) {
          // 申请机构后跳下载
          if (
            this.successToDown &&
            localStorage.getItem("perceptionSkip") == 2
          ) {
            if (this.$isIos() && localStorage.getItem("phoneType") == 1) {
              this.$toDownload(0);
              return;
            } else if (
              !this.$isIos() &&
              localStorage.getItem("phoneType") == 2
            ) {
              this.$toDownload(0);
              return;
            } else if (localStorage.getItem("phoneType") == 0) {
              this.$toDownload(0);
              return;
            }
          }
          localStorage.setItem("submitSuccess", "1");
          await this.$router.replace("/superLoan");
          return;
        }

        localStorage.setItem("bigLoanProduct", JSON.stringify(data));
        this.product = JSON.parse(localStorage.getItem("bigLoanProduct"));

        // 这里获取到的产品如果是极速贷(productType为0)则直接跳转贷超，否则弹出协议并获得截图
        if (this.product.productType === 0) {
          // 该渠道直接跳失败，并且永远无法到达贷超
          if (process.env.VUE_APP_FAILED_TO_FAIL_PAGE) {
            await this.$router.replace("/fail");
            return;
          }

          // 该渠道直接跳失败，但是成功后的流程是正常的，能够前往贷超
          if (process.env.VUE_APP_FAILED_TO_FAIL_PAGE_BUT_CAN_TO_SUPER_LOAN) {
            await this.$router.replace("/fail");
            return;
          }

          // 该渠道没有匹配到产品就直接跳下载页面
          if (process.env.VUE_APP_FAILED_TO_DOWNLOAD) {
            this.$toDownload(0);
            return;
          }

          // 申请机构后跳下载
          if (
            this.successToDown &&
            localStorage.getItem("perceptionSkip") == 2
          ) {
            if (this.$isIos() && localStorage.getItem("phoneType") == 1) {
              this.$toDownload(0);
              return;
            } else if (
              !this.$isIos() &&
              localStorage.getItem("phoneType") == 2
            ) {
              this.$toDownload(0);
              return;
            } else if (localStorage.getItem("phoneType") == 0) {
              this.$toDownload(0);
              return;
            }
          }

          localStorage.setItem("submitSuccess", "1");
          await this.$router.replace("/special");
        } else {
          // 这里是匹配到了大额贷的情况，如果是本地api产品则不会弹窗，直接进件然后前往api中间页
          if (this.product.instApiState === 1) {
            // h5api直接跳贷超
            if (this.product.localState === 0) {
              // 申请机构后跳下载
              if (
                this.successToDown &&
                localStorage.getItem("perceptionSkip") == 2
              ) {
                if (this.$isIos() && localStorage.getItem("phoneType") == 1) {
                  this.$toDownload(0);
                  return;
                } else if (
                  !this.$isIos() &&
                  localStorage.getItem("phoneType") == 2
                ) {
                  this.$toDownload(0);
                  return;
                } else if (localStorage.getItem("phoneType") == 0) {
                  this.$toDownload(0);
                  return;
                }
              }
              localStorage.setItem("submitSuccess", "1");
              await this.$router.replace("/superLoan");
              return;
            } else if (process.env.VUE_APP_SUPER_LOAN_ONLY_LOCAL_STATE) {
              this.$toDownload(0);
              return;
            }
          }

          // 这里是大额贷或者api的h5产品，正常弹出协议即可
          this.loadingIsShow = false;

          // 处理协议
          this.replaceProtocolContent();
        }
      } catch (e) {
        this.loadingIsShow = false;
      }
    },
    // 处理协议，替换协议内容，弹出协议并获得截图
    replaceProtocolContent() {
      let protocolList = [];
      if (
        this.product.xdApiAgreementList &&
        this.product.xdApiAgreementList.length !== 0
      ) {
        protocolList = this.product.xdApiAgreementList;
      }

      // 把protocolList循环分别赋值给bigAgreementNameList和bigAgreementFileList
      this.product.bigAgreementName = protocolList.map(
        (protocol) => protocol.name
      );
      this.product.bigAgreementFile = protocolList.map(
        (protocol) => protocol.url
      );

      // 处理协议弹框顶部的多个协议
      if (this.product.bigAgreementName) {
        // api协议在获取时已经成为数组，因此不用重复调用split方法，其他为本地配置的协议时字段为字符串，因此改为数组
        const bigAgreementNameList = this.product.bigAgreementName;
        const bigAgreementFileList = this.product.bigAgreementFile;
        if (bigAgreementNameList.length === 1) {
          this.bigAgreementName.push(...bigAgreementNameList);
          this.bigAgreementFile.push(...bigAgreementFileList);
        } else {
          bigAgreementNameList.splice(1, 0, "个人信息授权书");
          bigAgreementFileList.splice(1, 0, "");
          this.bigAgreementName = bigAgreementNameList;
          this.bigAgreementFile = bigAgreementFileList;
          this.protocolsTitleIndex = 1;
          this.$nextTick(() => {
            this.protocolsTitleIndex = 1;
          });
        }
      }

      // 获取用户信息填入协议
      const userInfo = this.$getUserInfo();
      const userName = userInfo.userName
        .split("")
        .map((item, index) => {
          if (index === 0) {
            return item;
          } else {
            return "*";
          }
        })
        .join("");

      // 身份证号判空
      userInfo.idCard = userInfo.idCard ? userInfo.idCard : "";

      // 协议内容填充
      this.protocolContentHtml = this.protocolContentHtml.replace(
        "{name}",
        userName
      );
      this.protocolContentHtml = this.protocolContentHtml.replace(
        "{idCard}",
        userInfo.idCard.replace(/^(.{6})(?:\d+)(.{4})$/, "\$1********\$2")
      );
      this.protocolContentHtml = this.protocolContentHtml.replace(
        "{time}",
        getNowDate()
      );
      this.protocolContentHtml = this.protocolContentHtml.replace(
        "{institutionName}",
        this.product.name || ""
      );
      this.protocolContentHtml = this.protocolContentHtml.replace(
        "{companyName}",
        this.product.companyName || ""
      );
      this.protocolContentHtml = this.protocolContentHtml.replace(
        "{address}",
        this.product.address || ""
      );
      this.protocolContentHtml = this.protocolContentHtml.replace(
        "{productName}",
        this.product.productName || ""
      );
      this.protocolContentHtml = this.protocolContentHtml.replace(
        "{productCompanyName}",
        this.product.productCompanyName || ""
      );

      // 弹出协议
      this.protocolPopupIsShow = true;

      // 截图状态开始时不可以点击提交
      this.stopSubmit = true;

      // 弹出协议之后进行截图，截图需要等弹出动画结束
      setTimeout(() => {
        this.html2canvas();
      }, 300);
    },
    // 通过点击协议弹窗的同意按钮申请api产品
    async doSubmitApiProduct() {
      this.countDownCoverIsShow = true;

      setTimeout(async () => {
        // 该渠道不申请任何产品，直接跳转审核中页面
        if (process.env.VUE_APP_TO_IN_REVIEW_AFTER_SUBMIT) {
          await this.$router.replace("/inReview");
          return;
        }

        // 根据返回产品的jumpState字段判断后续是否跳转贷超(0跳转 1不跳转)
        localStorage.setItem("quickLoanToSuperLoan", this.product.jumpState);

        // 截图保存给后端
        const base64Img = localStorage.getItem("base64Img");
        const file = base64Img ? this.base64ToFile(base64Img, "image") : "";
        save_personal_protocol_image({
          data: file,
          ...this.product,
        });

        try {
          // 大额贷申请产品，api进件
          const { data } = await apply_big_loan_product({
            productId: this.product.id,
            instApiState: this.product.instApiState,
            bigLoanType: this.product.isKlhdProduct,
          });

          // api产品
          if (this.product.instApiState === 1 && data.result) {
            // api产品存入本地，在后续中间页需要使用
            localStorage.setItem("apiProduct", JSON.stringify(this.product));

            // api产品进件后要埋点
            api_product_user_apply({ channelSign: getSearch().channelSign });

            // 本地api产品，直接推送，然后跳转中间页即可
            if (this.product.localState === 1) {
              // 推送api产品
              await apply_api_product(this.product.id);

              // 跳转中间页，加个延迟，等待其他埋点请求发送完毕
              setTimeout(() => {
                location.reload();
              }, 800);

              return;
            }

            // 分润Api，直接跳转中间页
            if (this.product.localState === 2) {
              // 跳转中间页，加个延迟，等待其他埋点请求发送完毕
              setTimeout(() => {
                location.reload();
              }, 800);
              return;
            }

            // h5Api直接跳链接
            if (this.product.localState === 0) {
              // 特殊渠道api的h5也直接跳下载
              if (process.env.VUE_APP_H5_API_PRODUCT_TO_DOWNLOAD) {
                this.$toDownload(0);
                return;
              }

              // 申请机构后跳下载
              if (
                this.successToDown &&
                localStorage.getItem("perceptionSkip") == 2
              ) {
                if (this.$isIos() && localStorage.getItem("phoneType") == 1) {
                  this.$toDownload(0);
                  return;
                } else if (
                  !this.$isIos() &&
                  localStorage.getItem("phoneType") == 2
                ) {
                  this.$toDownload(0);
                  return;
                } else if (localStorage.getItem("phoneType") == 0) {
                  this.$toDownload(0);
                  return;
                }
              }

              // 跳链接
              location.replace(data.productDto.h5Url);
              return;
            }
          }

          // 这里是whole-process-51渠道的特殊配置
          if (process.env.VUE_APP_BIG_LOAN_PRODUCT_AFTER_APPLY_TO_DOWNLOAD) {
            this.$toDownload(0);
            return;
          }

          if (!data.result) {
            // 申请机构后跳下载
            if (
              this.successToDown &&
              localStorage.getItem("perceptionSkip") == 2
            ) {
              if (this.$isIos() && localStorage.getItem("phoneType") == 1) {
                this.$toDownload(0);
                return;
              } else if (
                !this.$isIos() &&
                localStorage.getItem("phoneType") == 2
              ) {
                this.$toDownload(0);
                return;
              } else if (localStorage.getItem("phoneType") == 0) {
                this.$toDownload(0);
                return;
              }
            }
            this.$toast.fail("该产品已下架");

            // 进件失败跳贷超
            await this.$router.replace("/superLoan");
            return;
          }

          // 存储字段，大额贷申请成功
          localStorage.setItem("bigLoanProductApplySuccess", "1");
          await this.$router.replace("/examining");
        } catch (e) {
          this.countDownCoverIsShow = false;
        }

        this.countDownCoverIsShow = false;
      }, 1500);
    },
    refuseApiProductProtocol() {
      // 首先关闭协议弹窗
      this.protocolPopupIsShow = false;
      // 等关闭动画300ms结束，弹出再次询问的弹框
      setTimeout(() => {
        this.refuseProtocolPopupIsShow = true;
      }, 300);
    },
    // 用户取消申请api产品
    apiProductApplyCancel() {
      if (process.env.VUE_APP_FAILED_TO_FAIL_PAGE) {
        this.$router.replace("/fail");
        return;
      }

      if (process.env.VUE_APP_FAILED_TO_FAIL_PAGE_BUT_CAN_TO_SUPER_LOAN) {
        this.$router.replace("/fail");
        return;
      }

      // 该渠道没有匹配到产品就直接跳下载页面
      if (process.env.VUE_APP_FAILED_TO_DOWNLOAD) {
        this.$toDownload(0);
        return;
      }

      // 申请机构后跳下载
      if (this.successToDown && localStorage.getItem("perceptionSkip") == 2) {
        if (this.$isIos() && localStorage.getItem("phoneType") == 1) {
          this.$toDownload(0);
          return;
        } else if (!this.$isIos() && localStorage.getItem("phoneType") == 2) {
          this.$toDownload(0);
          return;
        } else if (localStorage.getItem("phoneType") == 0) {
          this.$toDownload(0);
          return;
        }
      }
      localStorage.setItem("submitSuccess", "1");
      localStorage.setItem("applySubmit", "1");
      this.$router.replace("/superLoan");
    },
    // 截图
    getShareImgBase64() {
      return new Promise((resolve) => {
        setTimeout(() => {
          html2canvas(document.querySelector(".protocol-popup-container"), {
            // useCORS: true, // 【重要】开启跨域配置
            scale: 1,
          }).then((canvas) => {
            const imgData = canvas.toDataURL("image/jpeg", 1.0);
            resolve(imgData);
          });
        }, 800);
      });
    },
    html2canvas() {
      this.$nextTick(() => {
        this.getShareImgBase64().then((res) => {
          localStorage.setItem("base64Img", res);
          // 截图完成可以提交资料
          this.stopSubmit = false;
        });
      });
    },
    // 转换截图为file
    base64ToFile(data, fileName) {
      const dataArray = data.split(",");
      const byteString = atob(dataArray[1]);
      const options = {
        type: "image/jpeg",
        endings: "native",
      };
      const utf8Array = new Uint8Array(byteString.length);
      for (let i = 0; i < byteString.length; i++) {
        utf8Array[i] = byteString.charCodeAt(i);
      }
      const formData = new FormData();
      let fileOfBlob = new File([utf8Array], fileName + ".jpg", options); //返回文件流
      formData.append("image", fileOfBlob);
      return formData;
    },
    // 获取下载地址
    async getDownloadUrl() {
      const isIos = !!navigator.userAgent.match(
        /\(i[^;]+;( U;)? CPU.+Mac OS X/
      );
      try {
        if (isIos) {
          // 获取ios下载链接
          const { data } = await get_ios_download_url();
          if (data) {
            // 获取到了地址直接赋值
            this.downloadUrl = data;
          } else {
            // 没有获取到地址通过另一个接口获取
            const { data } = await get_download_url({ onlyKey: 1 });
            this.downloadUrl = data;
          }
        } else {
          // 获取android下载链接
          const { data } = await get_download_url({ onlyKey: 2 });
          this.downloadUrl = data;
        }
      } catch (e) {}
    },
  },
  created() {
    this.getDownloadUrl();
    // 进入此页面需要存值，代表api产品申请成功，该值是给特殊渠道使用
    localStorage.setItem("apiProductApplySuccess", "1");

    this.apiProduct = JSON.parse(localStorage.getItem("apiProduct"));

    // 获取产品展示列表信息
    this.getProductInfoList();

    this.getProtocolContentHtml();
  },
  mounted() {
    // 特殊渠道固定6s，其他渠道根据存储字段获取
    const countDown = process.env.VUE_APP_NOT_TO_SUPER_LOAN_REDIRECT_DOWNLOAD
      ? 6000
      : localStorage.getItem("examiningToSuperLoanCountDown") * 1000;

    // 0代表不自动跳
    if (countDown !== 0) {
      this.timer = setTimeout(() => {
        this.doJump();
      }, countDown);
    }
  },
};
</script>

<style lang="less" scoped>
@import url(./index.less);
</style>
